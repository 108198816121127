import React, { useRef, useEffect } from 'react';


const Terms = () => {
  const downloadRef = useRef(null);

  useEffect(() => {
  downloadRef?.current.click();

  setTimeout(() => {
    window.location.href = "/";
  }, 200);
  },[downloadRef]);
  return (
    <>
      <div className="pdf">
        <a ref={downloadRef} href="/pdfs/SEESAW_Terms+Conditions_2022.pdf" download="SEESAW_Terms+Conditions_2022"></a>
      </div>
    </>
  )
}

export default Terms;